import {Box, Button} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function HomePage() {

    const navigate = useNavigate()

    const [nbResultPerCall, setNbResultPerCall] = React.useState(20);
    const [nbCall, setNbCall] = React.useState(2);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 8, flexDirection: 'column'}}>
            <h1>Home</h1>
            <p>Pour tester avec 20 * 2 : <Button onClick={() => {navigate('/20/2')}} variant="outlined">20*2</Button></p>
            <p>Pour tester avec 100 * 2 : <Button onClick={() => {navigate('/100/2')}} variant="outlined">100*2</Button></p>
        </Box>
    )
}
