export function recursivFetching(numberOfItemWanted, retryCount, body, skip, dataCopy, setDataToDisplay, url,
                                 timeToSubCopy, setTimeToSub, timeCopy, setTime, numberRequestCopy,
                                 setNumberOfRequest, needAuth, setMetaData, setIsLoading) {

    let param
    if (needAuth) {
        param = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }
    } else {
        param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)

        }
    }


    if (retryCount === 0) {
        timeToSubCopy = new Date().getTime()
        setTimeToSub(timeToSubCopy)
    }

    return fetch(url, param).then((response) => {
        return response.json()
    }).then((data) => {
        if(data.metadata) {
            setMetaData(data.metadata.data)
        }
        if(data.data.length > 0) {
            if(retryCount < numberOfItemWanted) {
                numberRequestCopy++
                setNumberOfRequest(numberRequestCopy)
                data.data.map((e) => {
                    dataCopy.push(e)
                })
                setDataToDisplay(dataCopy)
                body.skip += Number(skip)
                return recursivFetching(numberOfItemWanted, retryCount + skip, body, skip, dataCopy, setDataToDisplay, url,
                    timeToSubCopy, setTimeToSub, timeCopy, setTime, numberRequestCopy, setNumberOfRequest, needAuth, setMetaData, setIsLoading)
            } else {
                console.log('fini')
                setIsLoading(false)
                timeCopy = new Date().getTime()
                setTime(timeCopy)
            }
        } else {
            console.log('ya rien')
            setIsLoading(false)
            timeCopy = new Date().getTime()
            setTime(timeCopy)
        }
    })
}

export function infiniteScrollFetch(numberOfItemWanted, body, dataToDisplay, setDataToDisplay, url, setIsCalling) {

    // ajouter ici l'option de recherche pour fix le scroll + recherche
    // j'ai un state avec la recherche mais il faut resconstruire le body en faite
    // dcp c'est de l'autre coté

    setIsCalling(true)

    let param = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }

    return fetch(url, param).then((response) => {
        return response.json()
    }).then((data) => {
        let dataCopy = dataToDisplay
        data.data.map((e) => {
            dataCopy.push(e)
        })
        setDataToDisplay(dataCopy)
        setIsCalling(false)
    })
}